<template>
  <div>
    <v-row class="my-4">
      <v-col cols="3">
        <h3>Reporting</h3>
      </v-col>
      <v-col cols="3">
        <v-select
          v-if="hide"
          :items="items"
          label="Advertiser"
          single-line
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-menu
          v-if="hide"
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-left="24"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRange"
              label="Picker without buttons"
              append-icon="mdi-calendar"
              single-line
              outlined
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            @input="dateMenu = false"
            range
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <!-- <v-select
          :items="items"
          label="Agency"
          single-line
          outlined
          dense
        ></v-select> -->
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row
      ><v-col v-if="hide" col="12"
        ><span class="caption grey--text lighten-5"
          >Last updated: 2022-01-01 : 10:00AM</span
        ></v-col
      ></v-row
    >

    <Reporting
      :headers="headers"
      :campaigns="all_campaigns_reports.data"
      :pagination="pagination"
      @triggerPageChange="setPageNumber($event)"
      @filterParams="getCampaigns($event)"
    ></Reporting>
  </div>
</template>

<script>
import Reporting from "../../../components/Tables/Reporting.vue";

export default {
  data: () => ({
    filtered_campaigns: [],
    selected_agency: null,
    selected_advertiser: null,
    fetched_advertisers: [],
    pagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
    },
     headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "campaign_id",
      },
      {
        text: "Campaign Name",
        align: "start",
        sortable: false,
        value: "campaign",
        width: "280px",
      },
      {
        text: "Status",
        value: "activity_status",
        width: "90px",
      },
      { text: "Advertiser", value: "client_name", width: "140px" },
      { text: "Objective", value: "campaign_objective", width: "120px" },
      { text: "Type", value: "campaign_type", width: "120px" },
      { text: "Bid Strategy", value: "bid_strategy", width: "120px" },
      {
        text: "Flight Pacing",
        value: "pacing",
        width: "180px",
      },
      { text: "Spend", value: "campaign_spend", width: "100px" },
      {
        text: "Impressions",
        value: "impressions",
        width: "150px",
      },
      { text: "Clicks", value: "clicks", width: "100px" },
      { text: "CTR", value: "ctr", width: "80px" },
      { text: "CPC", value: "cpc", width: "80px" },
      { text: "CPL", value: "cpl", width: "80px" },
      { text: "Leads", value: "leads", width: "100px" },
      { text: "Views", value: "views", width: "100px" },
      { text: "VTR", value: "vtr", width: "80px" },
    ],
    error: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dates: ["2021-01-01", "2022-12-31"],
    modal: false,
    dateMenu: false,
    search: "",
    apexLoading: false,
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    hide: false,
  }),
  components: {
    Reporting,
  },
  async mounted() {
    //Get campaigns
    this.getCampaigns();
  },
  computed: {
    dsp_campaigns() {
      return this.$store.state.dsp.dsp_campaigns;
    },
    dsp_advertisers() {
      return this.$store.state.dsp.dsp_advertisers;
    },
    agencies() {
      return this.$store.state.dsp.agencies;
    },
    dateRange() {
      return this.dates.join(" -- ");
    },
    all_campaigns_reports(){
      return this.$store.state.dsp.all_campaigns_reports
    }
  },
  watch: {
    dsp_campaigns() {
      this.filtered_campaigns = this.dsp_campaigns.data;

      this.pagination.page = this.dsp_campaigns.meta.current_page;
      this.pagination.totalItems = this.dsp_campaigns.meta.last_page;
      this.pagination.rowsPerPage = this.dsp_campaigns.meta.per_page;
    },
  },
  methods: {
    async getCampaigns(params) {
      let body = {
        app:'transsion',
        page: this.pagination.page,
        params: {
          per_page: params ? params.per_page : 10,
        },
      };

      //Campaigns
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getAppCampaignDspReporting", body);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    getColor(status) {
      if (status === "Paid") return "green";
      else if (status === "Credited") return "orange";
      else return "red";
    },

    //set page mumber
    async setPageNumber(page) {
      this.pagination.page = page;
    },

    //Filter by agency
    setFilterByAgency(selected_agency) {
      this.selected_agency = selected_agency;
      this.fetched_advertisers = this.selected_agency.client;
    },
    setfilterByAdvertiser(selected_advertiser) {
      this.selected_advertiser = selected_advertiser;

      this.filtered_campaigns = this.dsp_campaigns.data
        .filter(
          (campaign) =>
            campaign.status == "runnable" || campaign.status == "offline"
        )
        .filter(
          (campaign) => campaign.client_id === this.selected_advertiser.id
        );
    },
  },
};
</script>
